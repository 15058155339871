import React from "react";
import TicketList from "./ticketList";
import queryString from "query-string";
import { useCopy } from "utility/copyContext";
import { getRegFormsList } from "datasource/api";
import TicketErrorMessage from "./ticketErrorMessage";
import FullPageLoader from "components/FullPageLoader";
import { ticketsPageCriteoEvent } from "helpers/utils";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function TicketsDefaultView() {
  useWindowScrollTop();
  const { generalStr, tickets } = useCopy();
  const [isLoading, setIsLoading] = React.useState("");
  const [ticketData, setTicketData] = React.useState({});
  const { spId: sponsorOrganizationId } = queryString.parse(window.location.search);

  React.useEffect(() => {
    if (generalStr.regClosed) return;
    setIsLoading(true);
    getRegFormsList(generalStr.getRegistrationFormsApi, generalStr.registrationId)
      .then(res => {
        setTicketData(res.data);
      })
      .catch(err => {
        setTicketData(false);
      })
      .finally(res => {
        setIsLoading(false);
      });
  }, [generalStr.regClosed, generalStr.registrationId, generalStr.getRegistrationFormsApi]);

  React.useEffect(() => {
    ticketsPageCriteoEvent();
  }, []);

  return (
    <section className="global_section global_section_tickets" id="tickets">
      <FullPageLoader isLoading={isLoading} />
      {!ticketData ? (
        <TicketErrorMessage />
      ) : (
        ticketData.registrationForms?.length > 0 && (
          <React.Fragment>
            <div className="container">
              <h3
                className="global_section_title"
                dangerouslySetInnerHTML={{ __html: tickets.title }}></h3>
              {(ticketData?.logo ||
                ticketData?.description ||
                ticketData?.sponsorOrganizationName) && (
                <div className="global_section_tickets_content">
                  <div className="global_section_tickets_content_wrapper">
                    <strong>
                      {sponsorOrganizationId
                        ? ticketData?.sponsorOrganizationName
                        : ticketData.registrationForms?.length > 0
                        ? tickets.sectionTitle
                        : ""}
                    </strong>
                    <div
                      className="global_section_tickets_content_wrapper_desc"
                      dangerouslySetInnerHTML={{ __html: ticketData?.description }}
                    />
                  </div>
                  <div className="global_section_tickets_image">
                    <img alt="ticket" src={ticketData?.logo} />
                  </div>
                </div>
              )}
            </div>
            <TicketList ticketData={ticketData.registrationForms} />
            {generalStr.hasSponsorLogo && (
              <div className="container">
                <div className="sBrand_logo">
                  <img
                    src={generalStr.ticketsSponsorLogoLink}
                    alt={generalStr.ticketsSponsorLogoAltText}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )
      )}
    </section>
  );
}
