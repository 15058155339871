import React from "react";
import { useQuery } from "./useQuery";
import { useCopy } from "utility/copyContext";
import { Base64Util } from "helpers/base64Util";
import {
  FORM_ID,
  COUPON_CODE,
  SPONSORS_ORG_ID,
  REGISTRATION_ROUTE_INITIATE_REG
} from "helpers/constants";

export default function useGetTicketUrl(ticket) {
  const query = useQuery();
  const { generalStr } = useCopy();
  return React.useMemo(() => {
    const encodedTicketId = Base64Util.encode(ticket.id);
    const baseTicketUrl = `https://${generalStr.registrationBaseUrl}${REGISTRATION_ROUTE_INITIATE_REG}?${FORM_ID}=${encodedTicketId}`;

    const couponCode = query.get(COUPON_CODE);
    const sponsorOrgId = query.get(SPONSORS_ORG_ID);

    if (couponCode) {
      return `${baseTicketUrl}&${COUPON_CODE}=${couponCode}`;
    }
    if (sponsorOrgId) {
      return `${baseTicketUrl}&${SPONSORS_ORG_ID}=${sponsorOrgId}`;
    }
    return baseTicketUrl;
  }, [generalStr.registrationBaseUrl, query, ticket.id]);
}
